@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg) !important;
}

@font-face {
    font-family: "GameOfSquids";
    src: local("GameOfSquids"), url("../fonts/game_of_squids.ttf") format("truetype");
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --color-border: #d5dee4;
    --color-bg: #eef3f5;
}





body > #root > div {
    height: 100vh;
  }

.apexcharts-toolbar {
    top: -50px !important;
}

.apexcharts-toolbar div {
    margin-right: 8px;
}

.apexcharts-toolbar svg {
    width: 30px;
    height: 30px;
}

/*
.datepicker-input::-webkit-clear-button {
    display: visible;
}

.datepicker-input::-webkit-inner-spin-button {
    display: none;
}

.datepicker-input::-webkit-calendar-picker-indicator {
    color: #606d7a;
}

.datepicker-input {
    appearance: none;
    -webkit-appearance: none;
    color: #000000;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 14px;
    border: none;
    background: none;
    border-bottom: 3px solid #1976d2;
    padding: 6px;
    display: inline-block !important;
    visibility: visible !important;
    cursor: pointer;
}

.datepicker-input,
focus {
    color: #586869;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.datepicker-input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.datepicker-input::-webkit-calendar-picker-indicator:hover {
    color: #1976d2;
}
*/

.btn-outline {
    padding: .5rem .8rem;
    border: none;
    background-color: transparent;
    font-weight: bold;
    cursor: pointer;
}

.btn-outline:hover {
    background-color: #1976d20c;
}

.btn-outline-blue {
    color: #1976d2;
    border-bottom: 3px solid #1976d2;
}


.location-box .location {
    font-size: 28px;
    font-weight: 10;
    text-align: center;
    color: #000000;
}

.weather-box {
    text-align: center;
}

.weather-box .temp {
    color: #ffffff;
    font-size: 28px;
    font-weight: 10;
    margin-left: 10px;
}

.sun-img {
    -webkit-mask-image: -webkit-gradient(linear, left right, left right, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spin {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spin-5000 {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.paused {
    animation-play-state: paused;
}


.blob {


    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;

}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.dashboard-chart-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 5px;
}


.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: #FFFFFF;
    }

    100% {
        background-color: #bfbfbf;
    }
}

